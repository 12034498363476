import { loadScript } from "client/lib/utils/loadScript";
import { HandleFile } from "client/types/HandleFile";
import { UploadOptions } from "client/types/UploadOptions";
import { useEffect, useState } from "react";
import { useValidateFiles } from "./useValidateFiles";

const API_KEY = "AIzaSyBCd1wCGWEIRGi4rfhOdj07f1b0nOiZBfk";
const CLIENT_ID = "288301801020-ihei361u6m6bbuupiug4nd4jjdrrrsf9.apps.googleusercontent.com";
const scope = "https://www.googleapis.com/auth/drive";
let pickerInited = false;
let tokenClient: google.accounts.oauth2.TokenClient | null = null;
let gisInited = false;

export const useGoogleDriveUpload = (handleFile: HandleFile, options: UploadOptions, shouldLoad: boolean) => {
    const [accessError, setAccessError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        if (isLoading) return;
        gisLoaded();
    };

    function gisLoaded() {
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope,
            callback: createPicker,
        });
        gisInited = true;
        tokenClient.requestAccessToken({ prompt: "consent" });
    }

    function onPickerApiLoad() {
        pickerInited = true;
    }

    function createPicker(tokenResponse: google.accounts.oauth2.TokenResponse) {
        if (tokenResponse.error) {
            setAccessError(true);
            return;
        }
        setAccessError(false);
        const pickerBuilder = new google.picker.PickerBuilder();
        pickerBuilder.addView(google.picker.ViewId.DOCS);
        pickerBuilder.setSelectableMimeTypes(options.supportedExtensions.map(option => `image/${option}`).join(","));
        pickerBuilder.setOAuthToken(tokenResponse.access_token);
        pickerBuilder.setDeveloperKey(API_KEY);
        pickerBuilder.setCallback((data) => pickerCallback(data, tokenResponse));
        options.allowMultiple && pickerBuilder.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
        const picker = pickerBuilder.build();
        picker.setVisible(true);
    }

    const { validateFiles } = useValidateFiles(options.supportedExtensions);
    async function pickerCallback(
        data: google.picker.ResponseObject,
        tokenResponse: google.accounts.oauth2.TokenResponse,
    ) {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
            const docs = data[google.picker.Response.DOCUMENTS];

            docs.forEach(async (doc) => {
                options.uploadStart && options.uploadStart();
                const fileId = doc.id;

                handleFile(
                    validateFiles([
                        { file: new File([], doc.name), src: `https://lh3.googleusercontent.com/d/${doc.id}` },
                    ]),
                    "googleDrive",
                    {
                        fileIds: [fileId],
                        filenames: [doc.name],
                        gtoken: JSON.stringify({
                            ...tokenResponse,
                            client_id: CLIENT_ID,
                        }),
                        access_token: tokenResponse.access_token,
                        fetch_url: `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
                    },
                );
            });
        }
    }

    const handleScriptLoad = () => {
        setIsLoading(true);
        loadScript("https://apis.google.com/js/api.js", () => {
            gapi.load("picker", onPickerApiLoad);
            loadScript("https://accounts.google.com/gsi/client", () => {
                setIsLoading(false);
            });
        });
    };

    useEffect(() => {
        shouldLoad && handleScriptLoad();
    }, [shouldLoad]);

    return {
        handleClick,
        accessError,
        setAccessError,
        isLoading,
    };
};
