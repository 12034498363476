import { allSupportedExtensions } from "store/features/resizeDownload/types/resizeTypes";

export const extensionsConfig = {
    "bulk-resize": allSupportedExtensions,
    "color-picker": allSupportedExtensions,
    "compressor": ["jpg", "jpeg", "png"],
    "flip": allSupportedExtensions,
    "rotate": allSupportedExtensions,
    "crop": allSupportedExtensions,
    "enlarge": allSupportedExtensions,
    "resizer": allSupportedExtensions,
    "meme-generator": allSupportedExtensions,
};
